<script setup lang="ts">
import { inject, provide, ref, watch, type Ref } from "vue";
import { navigationKey, registerKey, type ColumnMode } from "./navigation";

const props = defineProps<{
  navKey: string;
}>();

const initiated = ref(false);
const currentMode = ref<ColumnMode>("Empty");
var columnNumber = -1;
const register = inject(registerKey);
const navigationStack = ref(null) as Ref<HTMLElement | null>;

function init() {
  if (columnNumber < 0 && register) {
    columnNumber = register(props.navKey, currentMode);
    provide(navigationKey, props.navKey);
  }
}

function watchMode() {
  if (initiated.value) {
    return;
  }
  initiated.value = true;

  watch(
    currentMode,
    (newMode, oldMode) => {
      if (!navigationStack.value) {
        return;
      }

      if (columnNumber > 1 && newMode !== "Empty") {
        navigationStack.value.parentElement?.classList.remove("notLoaded");
      }

      if (oldMode === newMode) {
        return;
      }

      if (newMode === "Empty") {
        // Removing element from left
        if (oldMode === "Normal") {
          // navigationStack.value.addEventListener(
          //   "animationend",
          //   () => {
          //     navigationStack.value?.classList.add("hidden");
          //     navigationStack.value?.classList.remove("entering");
          //   },
          //   { once: true }
          // );
          navigationStack.value.classList.add("hidden");
          // Removing element from right
        } else if (oldMode === "Extended") {
          navigationStack.value.classList.add("hidden");
        }
      } else if (newMode === "Normal") {
        // Moving element from right to left with an animation
        if (oldMode !== "Extended") {
          navigationStack.value?.classList.remove("hidden");
        }
      } else if (newMode === "Extended") {
        if (columnNumber > 1) {
          // Animating into the view
          navigationStack.value.classList.remove("hidden");
        } else {
          navigationStack.value.classList.remove("hidden");
        }
      }
    },
    { immediate: true }
  );
}

watch(
  navigationStack,
  (newContainer) => {
    if (newContainer !== null) {
      watchMode();
    }
  },
  { immediate: true }
);

init();
</script>
<template>
  <Teleport :to="'#nav-col' + columnNumber" v-if="columnNumber >= 0">
    <div ref="navigationStack" class="init">
      <slot></slot>
    </div>
  </Teleport>
</template>
<style lang="scss">
.hidden {
  display: none;
}

.entering {
  animation: slide-out 500ms forwards;
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
