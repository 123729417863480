<script setup lang="ts">
import "@ckeditor/ckeditor5-build-classic/build/translations/fr";
import { computed, defineProps, inject, ref, watch } from "vue";
import type { Ref } from "vue";
import {
  diff,
  templateCategorySelectOptions,
  templateSubCategorySelectOptions,
} from "@/types";
import type { Template } from "@/types";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import {
  templatesKey,
  mutateTemplateKey,
  createTemplateKey,
} from "@/logic/provide";
import { knownMacros, replaceMacros } from "@/logic/template";

import FormInput from "../FormInput.vue";
import FormSelect from "../FormSelect.vue";
import DocumentPreview from "./DocumentPreview.vue";
import _ from "lodash";

const props = defineProps<{
  template?: string;
  canEdit: boolean;
  editMode: "edit" | "create";
}>();

const emit = defineEmits<{
  "click:cancel": [];
  "click:select": [string];
}>();

// Handling model
const templates = inject(templatesKey);
const mutateTemplate = inject(mutateTemplateKey);
const createTemplate = inject(createTemplateKey);

const template = computed(() => {
  if (!templates || !props.template) {
    console.error("TemplateEditor: No template provided");
    return null;
  }
  const found_index = templates.value.index.get(props.template);
  return (
    (found_index !== undefined && templates?.value.list[found_index]) || null
  );
});
const model = ref(null) as Ref<Template | null>;
const printMode = ref(false);

const allMacros = computed(() => {
  return Object.keys(knownMacros).map((key) => {
    return {
      key: key,
      value: knownMacros[key as keyof typeof knownMacros] as string,
    };
  });
});

const badMacros = computed(() => {
  if (model.value && model.value.content) {
    const replaced = replaceMacros(model.value.content, {});
    return replaced.errors;
  }
  return new Set<string>();
});

watch(
  () => template,
  (newVal) => {
    if (newVal.value) {
      model.value = { ...newVal.value };
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.editMode,
  (newVal) => {
    if (newVal === "create") {
      model.value = {
        id: "",
        title: "Nouveau template",
        category: "Patient",
        sub_category: "Demande de renseignements",
        content: "Nouveau Template",
      };
    }
  },
  { immediate: true, deep: true }
);

// Actions

function onCancel() {
  emit("click:cancel");
}

function onSave() {
  if (model.value) {
    if (props.editMode === "create" && createTemplate) {
      var creation = { ...model.value } as any;
      delete creation.id;
      delete creation.__typename;
      createTemplate(creation);
    } else if (props.editMode === "edit" && template.value && mutateTemplate) {
      const patch = diff(template.value, model.value);
      if (!_.isEmpty(patch)) {
        mutateTemplate(model.value.id, patch);
      }
    }
    emit("click:cancel");
  }
}

function onSelect() {
  if (model.value) {
    emit("click:select", model.value.id);
  }
}

function onPrint() {
  printMode.value = true;
}

function closePrint() {
  printMode.value = false;
}

// Editor definition
const editor = ClassicEditor;
const editorConfig: EditorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "blockQuote",
    "|",
    "undo",
    "redo",
  ],
  language: "fr",
};
</script>
<template>
  <div>
    <DocumentPreview v-if="model && printMode" @click:cancel="closePrint">
      <div v-html="model.content"></div>
    </DocumentPreview>
    <template v-if="model && !printMode">
      <div class="edit">
        <div class="actions">
          <span class="button" @click="onCancel()">{{
            canEdit ? "Annuler" : "Retour"
          }}</span>
          <span class="button" @click="onPrint()" v-if="canEdit"
            >Aperçu impression</span
          >
          <span class="button" @click="onSave()" v-if="canEdit"
            >Sauvegarder</span
          >
          <span class="button" @click="onSelect()" v-else>Sélectionner</span>
        </div>
        <div class="title">
          <h2>{{ model.title }}</h2>
        </div>
        <div class="meta" v-if="canEdit">
          <div class="line">
            <div>
              <span>Classement:</span>
            </div>
            <div>
              <FormSelect
                v-model="model.category"
                :options="templateCategorySelectOptions"
                label="Catégorie"
              ></FormSelect>
            </div>
            <div>
              <FormSelect
                v-model="model.sub_category"
                :options="templateSubCategorySelectOptions"
                label="Sous-Catégorie"
              ></FormSelect>
            </div>
            <div class="grow">
              <FormInput
                v-model="model.title"
                type="text"
                label="Titre"
                placeholder="Titre du template"
              />
            </div>
          </div>
        </div>
        <Ckeditor
          v-model="model.content"
          :editor="editor"
          :config="editorConfig"
          :disabled="!canEdit"
          class="more"
        />
      </div>
      <div class="caption">
        <template v-if="badMacros.size > 0">
          <h3>⚠️ Ces macros sont inconnues ⚠️</h3>
          <template v-for="macro in badMacros" :key="macro">
            <div>
              <span>{{ macro }}</span>
            </div>
          </template>
          <br />
        </template>
        <h3>Macros disponibles</h3>
        <template v-for="macro in allMacros" :key="macro.key">
          <div>
            <span class="italic">{{ macro.key + " " }}</span
            >:
            <span>{{ macro.value }}</span>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.title {
  padding-left: 1em;
  margin-bottom: 1em;
}

.edit {
  padding: 1em;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 2em;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 1em;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    gap: 1em;
    margin: 1em;
  }

  .grow {
    flex-grow: 1;
  }
}

.caption {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
</style>
