<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref, type Ref } from "vue";
import Navigation from "./Navigation.vue";
import { openSecondaryViewKey } from "./navigation";

const props = defineProps<{
  navKey: string;
  level: number;
}>();

const openSecondaryView = inject(openSecondaryViewKey);

const elements = ["Earth", "Water", "Air", "Fire"].map((element) => {
  return element + " " + props.level;
});

const selected = ref(null) as Ref<string | null>;

function selectElement(element: string) {
  selected.value = element;
  openSecondaryView?.(props.navKey);
}

onMounted(() => {
  console.log("Component allocated", props.navKey, props.level);
});

onUnmounted(() => {
  console.warn("Component deallocated", props.navKey, props.level);
});
</script>
<template>
  <Navigation :navKey="props.navKey">
    <template v-slot:default>
      <div class="list">
        <template v-for="element in elements" :key="element">
          <div class="element">
            <h2
              @click.stop="selectElement(element)"
              :class="{ red: element === selected }"
            >
              {{ element }}
            </h2>
          </div>
        </template>
      </div>
      <TestNav
        :navKey="props.navKey + '-sec'"
        :level="props.level - 1"
        v-if="selected !== null && level > 1"
      />
    </template>
  </Navigation>
</template>
<style lang="scss">
.list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex-wrap: wrap;
}

.element {
  padding: 1rem;
  border: 1px solid black;
  margin: 1rem;
  cursor: pointer;
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 1.5rem;
  &.red {
    background-color: red;
  }
}
</style>
