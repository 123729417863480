<script setup lang="ts">
import { computed, inject, provide, ref } from "vue";
import ActorDisplay from "../actors/ActorDisplay.vue";
import DentistDisplay from "../actors/DentistDisplay.vue";
import HealthCenterDisplay from "../actors/HealthCenterDisplay.vue";
import StakeholderSelector from "../actors/StakeholderSelector.vue";
import EventList from "../event/EventList.vue";
import {
  referenceTo,
  type Actor,
  type Case,
  type CaseEdit,
  type Dentist,
  type Document,
  type HealthCenter,
  type Template,
  type Event,
  type TemplateCategory,
} from "@/types";
import {
  actorsKey,
  attachDocumentKey,
  dentistKey,
  displayDocumentKey,
  healthCentersKey,
  protagonistsKey,
} from "@/logic/provide";
import { findStakeholder } from "@/logic/search";
import Navigation from "../navigation/Navigation.vue";
import {
  closeSecondaryViewKey,
  navigationKey,
  openSecondaryViewKey,
} from "../navigation/navigation";
import TemplateList from "../templates/TemplateList.vue";
import DocumentEditor from "../templates/DocumentEditor.vue";

// Edit
const props = defineProps<{
  case: Case;
  panelX: number;
}>();

const emit = defineEmits<{
  "update:case": [element: CaseEdit];
}>();

const actors = inject(actorsKey); // used when selecting a case
const dentists = inject(dentistKey); // used when selecting a case
const healthCenters = inject(healthCentersKey); // used when selecting a case

const openSecondaryView = inject(openSecondaryViewKey);
const closeSecondaryView = inject(closeSecondaryViewKey);
const currentNavKey = inject(navigationKey);

const currentActorSelection = computed(() => {
  if (!actors || !dentists || !healthCenters) {
    return { accused: undefined, plaignants: undefined };
  }

  const sourceAccused = props.case.targets || [];
  const sourcePlaigants = props.case.sources || [];

  return {
    accused: findStakeholder(sourceAccused, actors, dentists, healthCenters),
    plaignants: findStakeholder(
      sourcePlaigants,
      actors,
      dentists,
      healthCenters
    ),
  };
});

provide(protagonistsKey, currentActorSelection);

function addPlaignant(p: Actor | Dentist | HealthCenter) {
  const current = [...props.case.sources];
  current.push(referenceTo(p));

  emit("update:case", { sources: current });
}

function remPlaignant(p: Actor | Dentist | HealthCenter) {
  const current = [...props.case.sources];
  const tmp = current.filter((el) => el.id != p.id);

  emit("update:case", { sources: tmp });
}

function addAccused(p: Actor | Dentist | HealthCenter) {
  const current = [...props.case.targets];
  current.push(referenceTo(p));
  const patch: CaseEdit = {
    targets: current,
    case_concerns_center: current.find((a) => a.type === "cntr") !== undefined,
  };
  emit("update:case", patch);
}

function remAccused(p: Actor | Dentist | HealthCenter) {
  const current = [...props.case.targets];
  const edited = current.filter((el) => el.id != p.id);
  const patch: CaseEdit = {
    targets: edited,
    case_concerns_center: edited.find((a) => a.type === "cntr") !== undefined,
  };

  emit("update:case", patch);
}

// Handling of the template selection / documents editing
const selectedMessageCategory = ref<TemplateCategory | null>(null);
const selectedTemplate = ref<Template | null>(null);
const currentDocument = ref<Document | null>(null);
const currentDocumentTarget = ref<Actor | Dentist | HealthCenter | null>(null);

const selectionPanelX = ref(props.panelX);
var foundSelectionRegister = (() => {}) as (document: Document | null) => void;

function selectDocumentEvent(event: Event) {
  if (event.type === "Tentative de Conciliation") {
    selectedMessageCategory.value = "Administratif";
    return;
  }

  if (!actors || !dentists || !healthCenters) {
    selectedMessageCategory.value = "Patient";
    return;
  }

  const target = findStakeholder(
    [{ id: event.actor_id || null, name: "", type: null }],
    actors,
    dentists,
    healthCenters
  );

  if (target && target?.found.length > 0) {
    const foundTarget = target.found[0];
    currentDocumentTarget.value = foundTarget;
    const isStudent =
      "type" in foundTarget && foundTarget.type === "Praticien Etudiant";
    const isCenter = "finess" in foundTarget;
    const isDentist = "cno_id" in foundTarget;
    if (isStudent || isCenter || isDentist) {
      selectedMessageCategory.value = "Praticiens & Centres";
      return;
    }
  }

  selectedMessageCategory.value = "Patient";
  return;
}

// Provide the function to select a document within a template list tailored to the provided event.
function selectDocument(
  event: Event,
  panelX: number
): Promise<Document | null> {
  selectDocumentEvent(event);
  selectionPanelX.value = panelX;
  if (openSecondaryView && currentNavKey) {
    openSecondaryView(currentNavKey);
  }
  return new Promise((resolve) => {
    foundSelectionRegister = (document: Document | null) => {
      resolve(document);
    };
  });
}
provide(attachDocumentKey, selectDocument);

function displayDocument(
  document: Document,
  event: Event | null,
  panelX: number
): Promise<Document | null> {
  cleanupDocumentSelection();
  selectionPanelX.value = panelX;
  currentDocument.value = document;
  if (event) {
    selectDocumentEvent(event);
    selectedMessageCategory.value = null; // so there is no template selection
  }

  if (openSecondaryView && currentNavKey) {
    openSecondaryView(currentNavKey);
  }
  return new Promise((resolve) => {
    foundSelectionRegister = (document: Document | null) => {
      resolve(document);
    };
  });
}
provide(displayDocumentKey, displayDocument);

function onTemplateselected(template: Template | null) {
  selectedMessageCategory.value = null;

  if (template === null) {
    onDocumentSelected();
    return;
  }

  selectedTemplate.value = template;
  currentDocument.value = {
    title: template.title,
    html_content: "",
  };
}

function cleanupDocumentSelection() {
  selectedTemplate.value = null;
  selectedMessageCategory.value = null;
  currentDocument.value = null;
  currentDocumentTarget.value = null;
  currentDocumentTarget.value = null;
  foundSelectionRegister = (() => {}) as (document: Document | null) => void;
}

function onDocumentSelectionCancel() {
  if (closeSecondaryView && currentNavKey) {
    closeSecondaryView(currentNavKey);
  }
  foundSelectionRegister(null);
  cleanupDocumentSelection();
}

function onDocumentSelected() {
  if (closeSecondaryView && currentNavKey) {
    closeSecondaryView(currentNavKey);
  }
  foundSelectionRegister(currentDocument.value);
  cleanupDocumentSelection();
}
</script>
<template>
  <div class="container-panel">
    <div :style="{ 'padding-top': panelX + 'px', 'padding-bottom': '300px' }">
      <div @click.stop>
        <div class="sidecar">
          <div class="header-container">
            <h2>Requérants</h2>
          </div>
          <div class="listing">
            <template
              v-for="actor in currentActorSelection.plaignants?.found || []"
              :key="actor.id"
            >
              <DentistDisplay
                openMode="InlineList"
                v-if="'cno_id' in actor"
                :modelValue="actor"
                @click:remove="remPlaignant"
              />
              <HealthCenterDisplay
                v-else-if="'finess' in actor"
                :modelValue="actor"
                openMode="InlineList"
                @click:remove="remPlaignant"
              />
              <ActorDisplay
                v-else
                :modelValue="actor"
                openMode="InlineList"
                @click:remove="remPlaignant"
              />
            </template>
            <template
              v-for="name in currentActorSelection.plaignants?.notFound || []"
              :key="name"
            >
              <div>
                <h3>{{ name.name || name.id }}</h3>
                <span>non trouvé dans les fichiers.</span>
              </div>
            </template>
          </div>
          <div class="searchAdd">
            <StakeholderSelector
              label="Ajouter un requérant au dossier"
              cta="Ajouter"
              :include-actors="true"
              :include-health-centers="false"
              @selected="addPlaignant"
            />
          </div>
          <div class="header-container">
            <h2>Mis en cause</h2>
          </div>
          <div class="listing">
            <template
              v-for="actor in currentActorSelection.accused?.found || []"
              :key="actor.id"
            >
              <DentistDisplay
                openMode="InlineList"
                v-if="'cno_id' in actor"
                :modelValue="actor"
                @click:remove="remAccused"
              />
              <HealthCenterDisplay
                v-else-if="'finess' in actor"
                :modelValue="actor"
                openMode="InlineList"
                @click:remove="remAccused"
              />
              <ActorDisplay
                openMode="InlineList"
                v-else
                :modelValue="actor"
                @click:remove="remAccused"
              />
            </template>
            <template
              v-for="name in currentActorSelection.accused?.notFound || []"
              :key="name"
            >
              <div>
                <h3>{{ name.name || name.id }}</h3>
                <span>non trouvé dans les fichiers.</span>
              </div>
            </template>
          </div>
          <div class="searchAdd">
            <StakeholderSelector
              label="Ajouter un mis en cause au dossier"
              cta="Ajouter"
              :include-actors="false"
              :include-health-centers="true"
              @selected="addAccused"
            />
          </div>
        </div>
      </div>
      <EventList
        :caseID="props.case.id"
        :canAdd="props.case.case_status != 'Classé'"
        :key="props.case.id"
        @click.stop
      />
    </div>
  </div>
  <Navigation nav-key="Document">
    <TemplateList
      v-if="selectedMessageCategory !== null"
      :can-add="false"
      :can-edit="false"
      :category="selectedMessageCategory"
      :panelX="selectionPanelX"
      @click:select="onTemplateselected"
    />
    <DocumentEditor
      v-else-if="currentDocument"
      :templateTitle="currentDocument.title"
      :templateContent="selectedTemplate?.content || undefined"
      :recipient="currentDocumentTarget || undefined"
      :cancelMessage="selectedTemplate ? 'Annuler' : undefined"
      :panelX="selectionPanelX"
      v-model="currentDocument.html_content"
      @click:cancel="onDocumentSelectionCancel"
      @click:close="onDocumentSelected"
    />
  </Navigation>
</template>
<style scoped lang="scss">
.container-panel {
  padding-left: 2em;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;

  border-bottom-width: 6px;
  border-bottom-style: solid;
  border-color: inherit;
  white-space: nowrap;

  h2 {
    color: inherit;
  }
}

.searchAdd {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 0.3em; // compensates card in container above
}

.listing {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-left: 1em;
  row-gap: 2em;
}
</style>
