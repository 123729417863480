<script setup lang="ts">
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import type { Actor, Dentist, HealthCenter } from "@/types";

import "@ckeditor/ckeditor5-build-classic/build/translations/fr";
import {
  computed,
  defineProps,
  inject,
  nextTick,
  ref,
  watch,
  type Ref,
} from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { protagonistsKey } from "@/logic/provide";

import { replaceMacros } from "@/logic/template";
import DocumentPreview from "./DocumentPreview.vue";
import {
  closeSecondaryViewKey,
  navigationKey,
  openSecondaryViewKey,
} from "../navigation/navigation";
import { useToast } from "vue-toast-notification";

const props = defineProps<{
  templateTitle?: string;
  templateContent?: string;
  recipient?: Dentist | HealthCenter | Actor;
  modelValue: string;
  panelX?: number;
  cancelMessage?: string;
}>();

const emit = defineEmits<{
  "click:cancel": [];
  "click:close": [];
  "click:save": [];
  "update:modelValue": [string];
}>();

const closeSecondaryView = inject(closeSecondaryViewKey);
const openSecondaryView = inject(openSecondaryViewKey);
const currentNavKey = inject(navigationKey);
const toast = useToast();

// Handling model
const printMode = ref(false);
const model = ref(props.modelValue);
const panelX = computed(() => props.panelX || 0);
const mainDiv = ref(null) as Ref<HTMLDivElement | null>;

const protagonists = inject(protagonistsKey);
const badMacros = ref(new Set<string>());
const noDataMacros = ref(new Set<string>());

function initContent() {
  if (model.value.length === 0 && props.templateContent) {
    const accused = protagonists?.value.accused?.found.length
      ? protagonists.value.accused?.found[0]
      : undefined;
    const plaignant = protagonists?.value.plaignants?.found.length
      ? protagonists.value.plaignants?.found[0]
      : undefined;

    const replaceResult = replaceMacros(props.templateContent, {
      dentist: accused && "cno_id" in accused ? accused : undefined,
      patient: plaignant && "type" in plaignant ? plaignant : undefined,
      subject: props.recipient,
    });

    model.value = replaceResult.text;
    badMacros.value = replaceResult.errors;
    noDataMacros.value = replaceResult.notMatched;
  } else {
    reset();
    model.value = props.modelValue;
  }
}

watch(model, () => {
  emit("update:modelValue", model.value);
});

watch(
  props,
  () => {
    initContent();
  },
  { deep: true }
);

initContent();

// Actions
function reset() {
  model.value = "";
  badMacros.value = new Set<string>();
  noDataMacros.value = new Set<string>();
}

function onCancel() {
  reset();
  emit("click:cancel");
}

function onClose() {
  reset();
  emit("click:close");
}

function onPrint() {
  if (openSecondaryView && currentNavKey) {
    openSecondaryView(currentNavKey);
  }
  printMode.value = true;
}

function onCopyContent() {
  const htmlBlob = new Blob([model.value], { type: "text/html" });
  const textBlob = new Blob([model.value], { type: "text/plain" });
  const item = new ClipboardItem({
    "text/html": htmlBlob,
    "text/plain": textBlob,
  });
  navigator.clipboard
    .write([item])
    .then(() => {
      toast.success("Contenu copié dans le presse-papier", {
        duration: 2000,
        position: "top-right",
      });
    })
    .catch((e) => {
      console.error(e);
      toast.error("Impossible de copier le contenu", {
        duration: 2000,
        position: "top-right",
      });
    });
}

function closePrint() {
  console.log("close print", mainDiv.value);
  if (closeSecondaryView && currentNavKey) {
    closeSecondaryView(currentNavKey);
  }
  printMode.value = false;
  nextTick(() => {
    if (mainDiv.value) {
      setTimeout(() => {
        console.log("returnging to main div", mainDiv.value);
        mainDiv.value?.scrollIntoView({ behavior: "auto", block: "center" });
      }, 100);
    }
  });
}

// Editor definition
const editor = ClassicEditor;
const editorConfig: EditorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "blockQuote",
    "|",
    "undo",
    "redo",
  ],
  language: "fr",
};
</script>
<template>
  <div>
    <DocumentPreview v-if="printMode" @click:cancel="closePrint">
      <div v-html="model"></div>
    </DocumentPreview>
    <div
      class="edit"
      :style="{ 'padding-top': panelX + 'px', 'padding-bottom': '300px' }"
      v-else
    >
      <div class="actions">
        <span v-if="cancelMessage" class="button" @click="onCancel()">
          {{ cancelMessage }}
        </span>
        <span class="button" @click="onClose()"> Sauvegarder et Fermer</span>
        <span class="button" @click="onPrint()"> Aperçu impression </span>
        <span class="button" @click="onCopyContent()"> Copier le contenu </span>
      </div>
      <div class="title">
        <h2>{{ props.templateTitle }}</h2>
      </div>
      <div class="editorPane" ref="mainDiv">
        <div class="editor">
          <Ckeditor
            v-model="model"
            :editor="editor"
            :config="editorConfig"
            :disabled="false"
            class="more"
          />
          <div>
            <template v-if="badMacros.size > 0">
              <br />
              <h3>⚠️ Ces macros sont inconnues ⚠️</h3>
              <template v-for="macro in badMacros" :key="macro">
                <div>
                  <span>{{ macro }}</span>
                </div>
              </template>
              <br />
            </template>

            <template v-if="noDataMacros.size > 0">
              <h3>⚠️ Il manque des données pour remplacer ces macros ⚠️</h3>
              <template v-for="macro in noDataMacros" :key="macro">
                <div>
                  <span>{{ macro }}</span>
                </div>
              </template>
              <br />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.title {
  padding-left: 1em;
  margin-bottom: 1em;
}
.edit {
  padding: 1em;

  .editorPane {
    display: flex;
    flex-direction: row;
    gap: 1em;

    .editor {
      flex-grow: 1;
    }

    .protagonists {
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-left: 1em;
      margin-right: 1em;

      .headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        min-width: 690px;

        border-bottom-width: 6px;
        border-bottom-style: solid;
        border-color: inherit;
        white-space: nowrap;

        padding-left: 1em;

        h2 {
          color: inherit;
        }
      }

      .listing {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-top: 1em;
      }
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 1em;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    gap: 1em;
    margin: 1em;
  }

  .grow {
    flex-grow: 1;
  }
}
</style>
