<script setup lang="ts">
import { provide, type Ref } from "vue";
import {
  closeSecondaryViewKey,
  openSecondaryViewKey,
  registerKey,
  type ColumnMode,
} from "./navigation";

const stackedNavs = [] as { id: String; state: Ref<ColumnMode> }[];
var cursor = 0;

function registerNav(id: String, state: Ref<ColumnMode>): number {
  const alreadyRegisteredIndex = stackedNavs.findIndex((nav) => nav.id === id);
  const alreadyRegistered = stackedNavs[alreadyRegisteredIndex];
  if (alreadyRegistered !== undefined) {
    console.log("Navigation Stack: navigation id already registered", id);
    state.value = alreadyRegistered.state.value;
    alreadyRegistered.state = state;
    return alreadyRegisteredIndex;
  }

  stackedNavs.push({ id, state });
  setCursor(cursor);

  return stackedNavs.length - 1;
}

function openSide(id: String) {
  const navindex = stackedNavs.findIndex((nav) => nav.id === id);
  if (navindex === -1) {
    console.error("Navigation Stack: navigation id not found", id);
  } else {
    setCursor(navindex);
  }
}

function closeSide(id: String) {
  const navindex = stackedNavs.findIndex((nav) => nav.id === id);
  if (navindex === -1) {
    console.error("Navigation Stack: navigation id not found", id);
  } else {
    setCursor(Math.max(0, navindex - 1));
  }
}

function setCursor(update: number) {
  cursor = update;
  const navindex = cursor;
  if (navindex === -1 || navindex >= stackedNavs.length) {
    console.error("Navigation Stack: cursor out of bonds", cursor);
    setCursor(0);
  } else {
    stackedNavs[navindex].state.value = "Normal";
    if (navindex + 1 < stackedNavs.length) {
      stackedNavs[navindex + 1].state.value = "Extended";
    }

    for (let index = 0; index < navindex; index++) {
      stackedNavs[index].state.value = "Empty";
    }
    for (let index = navindex + 2; index < stackedNavs.length; index++) {
      stackedNavs[index].state.value = "Empty";
    }
  }
}

provide(registerKey, registerNav);
provide(openSecondaryViewKey, openSide);
provide(closeSecondaryViewKey, closeSide);
</script>
<template>
  <div class="NavManagerPlaceholder">
    <div class="invisible">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss"></style>
