<script setup lang="ts">
const props = defineProps<{
  orderTextTop: string;
  orderTextBottom: string;
}>();
</script>
<template>
  <div class="logoOrdre">
    <div>
      <img
        alt="CDO Paris"
        src="@/assets/icons/logo_oncd.png"
        width="100"
        height="100"
      />
    </div>
    <div class="lettering">
      <div>
        <h3>Ordre National</h3>
        <h3>Des Chirurgiens-Dentistes</h3>
      </div>
      <div class="bar"></div>
      <div>
        <h4>{{ props.orderTextTop }}</h4>
        <h4>{{ props.orderTextBottom }}</h4>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.logoOrdre {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  margin-bottom: 1em;

  .lettering {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    justify-content: center;
  }

  .bar {
    width: 100%;
    height: 1px;
    border-bottom: solid 1px;
    border-color: var(--vt-c-purple);
  }

  h3 {
    font-family: "Kiona";
    font-size: 1.3em;
    line-height: 1em;
    letter-spacing: -0.06rem;
  }

  h4 {
    font-family: "Kiona";
    font-size: 1em;
    line-height: 1em;
    letter-spacing: -0.06rem;
    color: var(--vt-c-purple);
  }
}
</style>
