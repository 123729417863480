import { ref, type Ref } from "vue";
import { defineStore } from "pinia";
import type { Member } from "@/types";

export const useLoginStore = defineStore("user", () => {
  const currentUser: Ref<Member | undefined> = ref(undefined);
  const login = (user: Member) => {
    currentUser.value = user;
  };
  const logout = () => {
    currentUser.value = undefined;
  };
  const updateToken = (token: string) => {
    if (currentUser.value) {
      currentUser.value.token = token;
    }
  };

  return { currentUser, login, logout, updateToken };
});
