import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  provideApolloClient,
  useLazyQuery,
  useMutation,
} from "@vue/apollo-composable";
import gql from "graphql-tag";

export const setupQueries = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  provideApolloClient(apolloClient);

  const actorsQuery = useLazyQuery(gql`
    query allActors {
      actors {
        id
        title
        type
        first_name
        last_name
        institution_name
        institution_finess
        contact {
          email
          address
          zipcode
          city
          phone_landline
          phone_cellular
        }
        notes
        cases
      }
    }
  `);

  const healthCentersQuery = useLazyQuery(gql`
    query allHealthCenters {
      centers {
        cases
        company_finess
        contact {
          email
          phone_landline
        }
        contact_imported {
          address
          city
          phone_landline
          zipcode
        }
        finess
        id
        name
        notes
        other_name
        siret_number
      }
    }
  `);

  const dentistQuery = useLazyQuery(gql`
    query prcts {
      dentists {
        id
        dep_id
        cdo_id
        cno_id
        rpps_id
        date_of_birth
        title
        first_name
        last_name
        contact {
          email
          address
          zipcode
          city
          phone_landline
          phone_cellular
        }
        contact_imported {
          email
          address
          zipcode
          city
          phone_landline
          phone_cellular
        }
        notes
        cases
      }
    }
  `);

  const filesQuery = useLazyQuery(gql`
    query cases {
      cases {
        id
        sources {
          name
          type
          id
        }
        targets {
          name
          type
          id
        }
        member_id
        case_type
        case_sub_type
        case_status
        case_sub_status
        case_concerns_center
        demand_receipt_date
        description
        creation_date
        last_update
      }
    }
  `);

  const membersQuery = useLazyQuery(gql`
    query members {
      members {
        id
        legacy_id
        display_name
      }
    }
  `);

  const importsQuery = useLazyQuery(gql`
    query imports {
      imports {
        id
        kind
        timestamp
      }
    }
  `);

  const templatesQuery = useLazyQuery(gql`
    query templates {
      templates {
        id
        title
        category
        sub_category
        content
      }
    }
  `);

  const dentistMutation = useMutation(
    gql`
      mutation editDentist($id: String!, $dentist: DentistEdit!) {
        editDentist(id: $id, dentist: $dentist)
      }
    `,
    { refetchQueries: ["prcts"] }
  );

  const actorMutation = useMutation(
    gql`
      mutation editActor($id: String!, $actor: ActorEdit!) {
        editActor(id: $id, actor: $actor)
      }
    `,
    { refetchQueries: ["allActors"] }
  );
  const createActorMutation = useMutation(
    gql`
      mutation createActor($actor: ActorCreation!) {
        addActor(actor: $actor)
      }
    `,
    { refetchQueries: ["allActors"] }
  );

  const healthCenterMutation = useMutation(
    gql`
      mutation editCenter($id: String!, $center: HealthCenterEdit!) {
        editCenter(id: $id, center: $center)
      }
    `,
    { refetchQueries: ["allHealthCenters"] }
  );

  const caseMutation = useMutation(
    gql`
      mutation editCase($id: String!, $edit: CaseEdit!) {
        editCase(id: $id, case: $edit)
      }
    `,
    { refetchQueries: ["cases", "prcts", "allActors", "allHealthCenters"] }
  );
  const createCaseMutation = useMutation(
    gql`
      mutation createCase($edit: CaseCreation!) {
        addCase(case: $edit)
      }
    `,
    { refetchQueries: ["cases", "prcts", "allActors", "allHealthCenters"] }
  );

  const eventMutation = useMutation(
    gql`
      mutation editEvent($id: String!, $event: EventEdit!) {
        editEvent(id: $id, event: $event)
      }
    `,
    { refetchQueries: ["events"] }
  );
  const createEventMutation = useMutation(
    gql`
      mutation createEvent($event: EventCreate!) {
        addEvent(event: $event)
      }
    `,
    { refetchQueries: ["events"] }
  );

  const templateMutation = useMutation(
    gql`
      mutation editTemplate($id: String!, $template: TemplateEdit!) {
        editTemplate(id: $id, template: $template)
      }
    `,
    { refetchQueries: ["templates"] }
  );

  const templateCreationMutation = useMutation(
    gql`
      mutation createTemplate($template: TemplateCreation!) {
        addTemplate(template: $template)
      }
    `,
    { refetchQueries: ["templates"] }
  );

  return {
    actorsQuery,
    healthCentersQuery,
    dentistQuery,
    filesQuery,
    membersQuery,
    importsQuery,
    templatesQuery,
    dentistMutation,
    actorMutation,
    createActorMutation,
    healthCenterMutation,
    caseMutation,
    createCaseMutation,
    eventMutation,
    createEventMutation,
    templateMutation,
    templateCreationMutation,
  };
};
