<script setup lang="ts">
import { ref, watch, type Ref } from "vue";
import LogoONCD from "../LogoONCD.vue";
import { MDBSwitch } from "mdb-vue-ui-kit";

const emit = defineEmits<{
  "click:cancel": [];
}>();

const withoutDecoration = ref(false);
const spacer = ref(null) as Ref<HTMLElement | null>;
const canUserDecorations = ref(true);

function onCancel() {
  emit("click:cancel");
}

function printPage() {
  window.print();
}

watch(
  spacer,
  (newVal: HTMLElement | null) => {
    if (newVal) {
      if (newVal.clientHeight < 2) {
        canUserDecorations.value = false;
        withoutDecoration.value = true;
      }
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="no-print actions">
    <span class="button" @click="onCancel()">Retour</span>
    <span class="button" @click="printPage()">Imprimer</span>
    <MDBSwitch
      v-model="withoutDecoration"
      :disabled="!canUserDecorations"
      label="Impression sur papier à entête"
    />
    <div v-if="!withoutDecoration">
      <span style="font-weight: bold"> Info: </span>
      <span
        >Imprimez sur du A4, marge-basse à 0cm, les autres marges à 1cm.</span
      >
    </div>
    <div v-if="!canUserDecorations">
      <span style="font-weight: bold"> Info: </span>
      <span>Le contenu du message est trop long pour tenir sur une page.</span>
    </div>
  </div>
  <div class="printable" :class="{ a4: !withoutDecoration }">
    <LogoONCD
      v-if="!withoutDecoration"
      order-text-top="Ordre Départemental"
      order-text-bottom="De Paris"
    />
    <div class="content">
      <div class="airOnTop"></div>
      <slot></slot>
    </div>
    <div class="footer" v-if="!withoutDecoration">
      <div class="bar"></div>
      <div class="info">
        <h4>27 Rue Ginoux | 75015 Paris</h4>
        <span>●</span>
        <span>Mail : paris@oncd.org</span>
        <span>●</span>
        <span>Tél : 01 42 60 49 73</span>
        <span>●</span>
        <span>Fax : 01 49 27 93 79</span>
      </div>
    </div>
  </div>
  <div class="no-print sep"></div>
</template>
<style lang="scss" scoped>
.actions {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1em;
  margin: 1em;

  padding-bottom: 0.5em;
  border-bottom: dotted 2px;
}

.sep {
  width: 100%;
  height: 3px;
  border-bottom: dotted 2px;
}

.printable {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1cm 1cm 0 1cm;
  width: calc(21cm - 2cm);

  &.a4 {
    height: calc(29.7cm - 1.2cm);
  }

  .spacer {
    flex-grow: 1;
  }

  .content {
    font-size: 10pt;
  }

  .airOnTop {
    height: 2cm;
  }

  .footer {
    position: absolute;
    bottom: 0cm;
    display: flex;
    flex-direction: column;

    .bar {
      width: calc(21cm - 100px);
      height: 2px;
      border-bottom: solid 2px;
      border-color: var(--vt-c-purple);

      margin-left: auto;
      margin-right: auto;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
      gap: 1em;
      margin: 1em 1em 1em 1em;

      font-weight: 500;
      font-size: x-small;

      h4 {
        color: var(--vt-c-purple);
        font-size: x-small;
      }
    }
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .printable {
    margin: 0em;
  }
}
</style>
